.timeline {
  position: relative;
  margin: 50px auto;
  padding: 40px 0;
  width: 1000px;
  box-sizing: border-box;
}

.heading {
  text-align: left;
  /* margin-bottom: 50px; */
  margin-left: 0;
}

h2 {
  font-size: 1.8em;
  margin-bottom: 10px;
}

span {
  font-size: 1.2em;
  color: #ccc;
}

.summary {
  display: flex;
  justify-content: center;

}

.summary h3 {
  background-color: #F8D90F;
  text-align: center;
  text-transform: uppercase;
  padding: 10px 20px;
  border: 1px solid #fff;
  border-radius: 0.5rem;

  font-size: 1rem;
  font-weight: 600;
}

.timeline::before {
  content: '';
  position: absolute;
  left: 50%;
  width: 2px;
  height: 90%;
  top: 7%;
  background-color: #F8D90F;

}

.timeline-event {
  position: relative;
  margin: 50px 0;
  width: 50%;
  padding: 20px;
  box-sizing: border-box;
  text-align: right;
}

.timeline-event::after {
  content: '';
  position: absolute;
  top: 30px;
  right: -13px;
  width: 25px;
  height: 25px;
  background-color: #F8D90F;
  border-radius: 50%;
  z-index: 1;
}

.timeline-event:nth-child(even) {
  left: 50%;
  text-align: left;
}

.timeline-event:nth-child(even)::after {
  left: -12px;
}

.timeline-event-copy {
  position: relative;
  padding: 20px;
  border-radius: 6px;
  background-color: #f2f2f2;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.timeline-event-thumbnail {
  margin-bottom: 10px;
  color: #666;
  font-size: 14px;
}

.timeline-event-description {
  color: #333;
  text-align: justify;
}

@media (max-width: 767px) {
  .summary {

    justify-content: start;

  }

  .timeline {
    width: 100%;
    padding-bottom: 0;
    padding-left: 10px;
  }

  .timeline::before {
    left: 20px;
    height: 100%;

  }

  .timeline-event {
    width: calc(100% - 80px);
    text-align: left !important;
    margin-left: 20px;
  }

  .timeline-event::after {
    left: -4% !important;

  }

  .timeline-event:nth-child(even) {
    left: 0;
  }
}