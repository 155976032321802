.about-and-contact-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px;
}

.about-and-contact-container>* {
  flex: 1;
  margin: 0 10px;
}