.go_to_top {
  position: fixed;
  bottom: 0;
  right: 5%;
  background: #f8d90f;
  color: #000;
  padding: 15px;
  border-radius: 100%;
  opacity: 0;
  transition: all 500ms ease-in;
  animation: 500ms hide linear;
  cursor: pointer;
}

.visible_goto_top {
  opacity: 1;
  bottom: 5%;
  transition: all 500ms ease-in;
}

.activeLink {
  color: #f8d90f;
}
