.project-heading {
  text-align: center;
  padding: 4rem 0 2rem 0;
}

.project-conatiner {
  max-width: 1140px;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 40px;
  padding-bottom: 5rem;
}

.project-card {
  background: #1a1919;
  padding: 1.2rem 1rem;
  height: 100%;
  width: 100%;
  display: grid;
  border-radius: 0.5rem;
  /* margin-top: 1rem; */
}

.project-card img {
  width: 100%;
  height: 100%;
  cursor: pointer;

}

.image-holder {
  height: 250px;
  width: 100%;
  background-size: 100%;
  background-position: 0 0;
  transition: background-position 2s ease-in;
  cursor: pointer;
}

.image-holder:hover {
  background-position: 0 100%;
  transition: background-position 2s ease-in;
}

.project-name {
  color: #fff;
  padding: 1rem 0;
  text-align: center;
}

.project-details {
  padding-bottom: 1rem;
  font-size: 1.1rem;
  text-align: initial;
  text-align: justify;
}

.project-details>p {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 15px;
  cursor: pointer;
  user-select: none;


}

.project-btn {
  padding: 0.8rem;
  text-align: center;
  position: relative;
  bottom: -10%;
  transform: translateY(10%);
}

.project-btn .btn {
  padding: 0.5rem 1rem;
  /* margin: 2rem; */
}

.project-btn .btn:hover {
  background: rgba(255, 255, 255, 0.2);
  color: #fff;
  transition: 0.3s;
}

@media screen and (max-width: 740px) {
  .project-conatiner {
    max-width: 90%;
    margin: auto;
    grid-template-columns: 1fr;
  }

}