.about-container {
  width: 100%;
  margin: 3rem 0;
  display: grid;
  gap: 1rem;
  align-items: start;
  justify-content: start;
  height: 100%;
}

.about-container h1 {
  text-align: start;
  /* margin: auto;
  padding: 0.2rem; */
  max-width: 350px;
}

.about-container p {
  text-align: start;
  /* margin: auto;
  padding: 1rem; */
  max-width: 750px;
  font-size: 1.2rem;
}

.about-container .about-link {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  margin: 2rem 0;
  gap: 80px;


}