 .hero{
  width: 100%;
  height: 100vh;
 }
 .hero .content{
  position: relative;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  text-align: center;
 }
 .hero .content h1{
  font-size: 3rem;
  padding: 0.6rem 0 1.5rem;
 }
 .hero .content p{
  font-size: 1.4rem;
  text-transform: uppercase;
  font-weight: 200;
 }
 .content .btn{
  margin: 1rem 0.2rem;
 }

 @media screen and(max-width:640px) {
  .hero .content h1{
    font-size: 3rem;
   
   }
   .hero .content p{
    font-size: 1.4rem;
   }
 }