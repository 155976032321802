.footer {
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
  padding: 20px 0;
  text-align: center;
}

.footer-container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  font-size: 16px;
}

#year {
  margin-left: 5px;
}

.seprator {
  color: #fff;
  margin: 0 10px;
}

.social-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.social-icon i,
.social-icon svg {
  color: #fff;
  margin: 0 10px;
  cursor: pointer;
  scale: 1.25;
}

.social-icon i:hover,
.social-icon svg:hover {
  color: #ddd;
}