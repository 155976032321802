/* label {
  color: #fff;
  margin-bottom: 0.5rem;
} */

.contactForm {
  display: flex;
  gap: 10%;
  justify-content: center;
  flex-wrap: wrap;
  /* max-width: 1150px; */
  width: 100%;
  margin: 30px 5px;
  padding: 50px;
  /* background-color: #1A1919; */
  /* box-shadow: 0 0 20px rgba(0, 0, 0, 0.1); */
  margin-bottom: 30px;
  /* border-radius: 0.5rem; */
}


.getInTouch {
  /* flex: 1; */
  padding-right: 40px;
  width: 40%;
}

.heading {
  margin-top: 0;
  text-align: center;
  /* padding: 20px; */
  font-size: 24px;
}

.getInTouch,
.form-container {
  margin-bottom: 30px;

}

.getInTouch p {
  color: #ccc;
  line-height: 1.6;
  font-size: 18px;
}


.contact-info p {
  margin: 10px 0;
  color: #F8D90F;
  /* padding: 5px; */
}

.contact-info span {
  margin-left: 10px;
  color: #ccc;
}

.form-container {
  /* flex: 1; */
  padding: 25px 0;
  /* border-left: 1px solid #ddd; */
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  /* width: 30%; */
  /* height: 65; */
  border-radius: 0.5rem;
  margin-top: 5px;
  min-width: 350px;
}

.form-container h2 {
  margin-top: 0;
  text-align: center;
  /* padding: 10px; */
  font-size: 24px;
}

form {
  display: flex;
  flex-direction: column;
  padding: 0rem 1rem;
  margin: auto;
  max-width: 600px;

}

form input,
form textarea {
  margin-bottom: 1rem;
  padding: 10px 18px;
  font-size: 1.2rem;
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.1);
  color: #f4f4f4;
  border-radius: 0.5rem;
  display: block;
  width: 100%;
  font-family: inherit;
}

@media (max-width: 665px) {

  .getInTouch {
    width: 100%;

  }

  .form-container {
    width: 80%;
    margin-top: 25px;
  }
}